import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import timezone from 'dayjs/plugin/timezone';

import tzData from '../resources/data/timezones.json';
dayjs.extend(utc);
dayjs.extend(timezone);

// captures $1=date, $2=time, $3=timezone offset
const ISO_STRING_REGEX = /(\d{4}-[01]\d-[0-3]\d)T([0-2]\d:[0-5]\d(?::[0-5]\d)?(?:\.\d+)?)([+-][0-2]\d:[0-5]\d|Z)/;

function padZeroTime(num: number, prefix = false) {
  if (num < 0) {
    return `${prefix ? '-' : ''}${num > -10 ? '0' : ''}${Math.abs(num)}`;
  }
  return `${prefix ? '+' : ''}${num < 10 ? '0' : ''}${num}`;
}

export function getTimeZoneByAbbr(abbr: string) {
  return tzData.timezones.find((timezone) => timezone.abbr === abbr);
}

export function getTimeZoneByName(name: string) {
  return tzData.timezones.find((timezone) => timezone.value === name);
}

export function getLocalTimezone() {
  const value = new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' }).format(new Date()).split(', ')[1];
  return getTimeZoneByName(value);
}

export function getTimeAsUTC(time: string) {
  const localTime = dayjs(time).utc(true); // Set datetime as UTC without changing value
  return localTime || time;
}

export const toJsTimeZoneName = (ianaZone: string, date?: Date): string =>
  new Intl.DateTimeFormat('en-US', { timeZone: ianaZone, timeZoneName: 'long' }).format(date).split(', ')[1];

export function getTimeZoneAbbreviation(ISOString: string, origTimezoneName?: string) {
  if (!ISOString) {
    return ISOString;
  }

  let timezoneName = origTimezoneName;
  // show local timezone short abbr
  if (!timezoneName) {
    timezoneName = new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' })
      .format(new Date(ISOString))
      .split(', ')[1];
  }

  const tz = getTimeZoneByName(timezoneName);
  return tz && tz.abbr;
}

// dayjs does not support timezone, add timezone abbr manually
export function formatDate(origValue: string, format = 'MMM DD, YYYY h:mm A Z', timezoneName?: string) {
  if (origValue) {
    const value = dayjs(origValue).toISOString();
    const tzOffsetRegex = /\sZ$/;
    const hasTzOffsetRegex = tzOffsetRegex.test(format);
    // if format pattern is w/o timezone, dayjs can handle it
    if (!hasTzOffsetRegex && !timezoneName) {
      return dayjs(value).format(format);
    }

    const formatWithoutOffset = format.replace(tzOffsetRegex, '');
    // remove timezone offset and format
    const tz = timezoneName && getTimeZoneByName(timezoneName);
    const dateStr = dayjs(tz ? value.replace(ISO_STRING_REGEX, '$1T$2') : value)
      .add(tz ? tz.offset : 0, 'hour')
      .format(formatWithoutOffset);

    if (!hasTzOffsetRegex) {
      return dateStr;
    }

    const timezoneStr = getTimeZoneAbbreviation(value, timezoneName);
    if (!timezoneStr) {
      return dateStr;
    }

    return `${dateStr} ${timezoneStr}`;
  } else {
    return '';
  }
}

export function formatDateWithoutTime(origValue: string, timezoneName?: string) {
  return formatDate(origValue, 'YYYY-MM-DD', timezoneName);
}

export function getCombinedDateStr(dateStr?: string, timezoneName = 'Greenwich Mean Time') {
  if (!dateStr) {
    return dateStr;
  }

  // test against `2020-04-10T12:34` format
  const result = /(\d{4}-[01]\d-[0-3]\d)(T[0-2]\d:[0-5]\d(?::[0-5]\d)?(?:\.\d+)?)?/.exec(dateStr);

  // if date portion is not detected, return original string
  if (!result || !result[1]) {
    return dateStr;
  }

  let finalStr = dateStr;

  // if time portion is not detected, set the default time
  if (!result[2]) {
    finalStr += 'T00:00';
  }
  // we need to have work around here, as Standrad replace, because of IANA timezones
  const tz = getTimeZoneByName(timezoneName) || getTimeZoneByName(timezoneName.replace(' Standard', ''));
  const offset = tz && tz.offset;
  if (offset !== undefined) {
    const hours = parseInt(`${offset}`, 10);
    const minutes = (offset * 60) % 60;
    finalStr = `${finalStr}${padZeroTime(hours, true)}:${padZeroTime(minutes)}`;
  }

  return finalStr;
}

export function isDateInThePast(
  date: dayjs.Dayjs,
  timezoneName: string,
  overrideCurrentDate?: string, // Optional ISO string for the current date
  overrideUTCTimezone?: string // Optional timezone name for the override date
): boolean {
  const tz = getTimeZoneByName(timezoneName) || getTimeZoneByName(timezoneName.replace(' Standard', ''));
  const timezoneOffset = tz?.offset;

  if (timezoneOffset === undefined) {
    return false;
  }

  const dateStr = getCombinedDateStr(date.format('YYYY-MM-DDTHH:mm'), timezoneName);
  const offsetInHours = timezoneOffset / 60;
  const dateInUTC = dayjs(dateStr);
  const dateInTimezone = dateInUTC.utcOffset(offsetInHours * 60); // Set the correct UTC offset

  let currentDateInTimezone;

  if (overrideCurrentDate && overrideUTCTimezone) {
    const overrideDateStr = getCombinedDateStr(
      dayjs(overrideCurrentDate).format('YYYY-MM-DDTHH:mm'),
      overrideUTCTimezone
    );

    const overrideTz =
      getTimeZoneByName(overrideUTCTimezone) || getTimeZoneByName(overrideUTCTimezone.replace(' Standard', ''));
    const overrideOffsetInHours = overrideTz?.offset ? overrideTz.offset / 60 : 0; // Default to UTC if not found
    const overrideDateInUTC = dayjs(overrideDateStr);

    currentDateInTimezone = overrideDateInUTC.utcOffset(overrideOffsetInHours * 60); // Apply override timezone offset
  } else {
    currentDateInTimezone = dayjs().utcOffset(offsetInHours * 60); // Current time in the provided timezone
  }

  return dateInTimezone.isBefore(currentDateInTimezone);
}

export function extractTimeStr(ISOString?: string) {
  if (!ISOString) {
    return ISOString;
  }
  const result = ISO_STRING_REGEX.exec(ISOString);
  return result ? result[2] : undefined;
}

export function removeTimezoneOffset(ISOString?: string) {
  if (!ISOString) {
    return ISOString;
  }
  return ISOString.replace(ISO_STRING_REGEX, '$1T$2');
}

/**
 * Returns ISO string with only date and time against the given timezone
 *
 * @export
 * @param {string} dateStr
 * @param {string} timezoneName
 * @returns
 */
export function getValueWithoutTimezone(dateStr: string, timezoneName: string) {
  if (!dateStr) {
    return dateStr;
  }
  const { offset = 0 } = getTimeZoneByName(timezoneName) || {};
  const momentValue = dayjs(dateStr).add(offset, 'hour');
  const utcISOValue = momentValue.toISOString();

  return removeTimezoneOffset(utcISOValue);
}

/**
 * @param {string|import('moment').Moment} start
 * @param {string|import('moment').Moment} end
 * @returns {import('moment').Moment[]}
 */
export const getDateRange = (start: string | moment.Moment, end: string | moment.Moment): moment.Moment[] => {
  const s = moment.isMoment(start) ? start : moment(start);
  const e = moment.isMoment(end) ? end : moment(end);
  return [...Array(1 + e.diff(s, 'days')).keys()].map((n) => moment(s).add(n, 'days'));
};

/**
 * This does not check all the valid ISO format, just based on the regex we define as `ISO_STRING_REGEX`
 *
 * @export
 * @param {string} [ISOString]
 * @returns
 */
export function isISOString(ISOString?: string) {
  if (!ISOString) {
    return false;
  }
  return ISO_STRING_REGEX.test(ISOString);
}

export function diffInHours(start: any, end: any) {
  return dayjs(end).diff(dayjs(start), 'h');
}

export const areValidDates = (startDate?: string, endDate?: string, timezone?: string): boolean => {
  if (!startDate || !endDate) {
    return false;
  }
  const now = dayjs();
  const formattedStartDate = dayjs(getCombinedDateStr(startDate, timezone));
  const formattedEndDate = dayjs(getCombinedDateStr(endDate, timezone));

  return !!(startDate && endDate && formattedStartDate > now && formattedEndDate > formattedStartDate);
};

/**
 * Returns the date range in a special format: MMMM DD-DD, YYYY
 *
 * @export
 * @param {string} start
 * @param {string} end
 * @param {Record<string, any>} [formats]
 * @returns {string}
 */
export const formatDateRangeToMonthDayDayYear = (start: string, end: string, formats?: Record<string, any>): string => {
  const { month, day, year } = {
    month: 'MMMM',
    day: 'DD',
    year: 'YYYY',
    ...formats,
  };
  const startFormat =
    !moment(start).isSame(end, 'day') && moment(start).isSame(end, 'year')
      ? `${month} ${day}`
      : `${month} ${day}, ${year}`;
  let endFormat = '';

  if (!moment(start).isSame(end, 'day')) {
    if (moment(start).isSame(end, 'month')) {
      endFormat += day;
    } else {
      endFormat += `${month} ${day}`;
    }
    endFormat += `, ${year}`;
  }

  return endFormat ? `${formatDate(start, startFormat)}-${formatDate(end, endFormat)}` : formatDate(start, startFormat);
};
